export const GET_DRIVERS_PLANNINGS = 'GET_DRIVERS_PLANNINGS'
export const GET_DRIVERS_PLANNINGS_SUCCESS = 'GET_DRIVERS_PLANNINGS_SUCCESS'
export const GET_DRIVERS_PLANNINGS_FAILURE = 'GET_DRIVERS_PLANNINGS_FAILURE'

export const AFFECT_TOUR = 'AFFECT_TOUR'
export const AFFECT_TOUR_SUCCESS = 'AFFECT_TOUR_SUCCESS'
export const AFFECT_TOUR_FAILURE = 'AFFECT_TOUR_FAILURE'

export const SIMULATE_TOURS = 'SIMULATE_TOURS'
export const SIMULATE_TOURS_SUCCESS = 'SIMULATE_TOURS_SUCCESS'
export const SIMULATE_TOURS_FAILURE = 'SIMULATE_TOURS_FAILURE'

export const RECALCULATE_TOURS = 'RECALCULATE_TOURS'
export const RECALCULATE_TOURS_SUCCESS = 'RECALCULATE_TOURS_SUCCESS'
export const RECALCULATE_TOURS_FAILURE = 'RECALCULATE_TOURS_FAILURE'

export const CREATE_TOURS = 'CREATE_TOURS'
export const CREATE_TOURS_SUCCESS = 'CREATE_TOURS_SUCCESS'
export const CREATE_TOURS_FAILURE = 'CREATE_TOURS_FAILURE'

export const REMOVE_ERROR_PLANNIFICATION = 'REMOVE_ERROR_PLANNIFICATION'

export const VIEW_PLANNIFICATION = 'VIEW_PLANNIFICATION'

export const UPDATE_FOCUSED_TOURS = 'UPDATE_FOCUSED_TOURS'

export const UPDATE_ORDERS_ARRAY = 'UPDATE_ORDERS_ARRAY'

export const CHECKED_ALL_ORDERS = 'CHECKED_ALL_ORDERS'
export const CHECKED_ORDER = 'CHECKED_ORDER'
export const RESET_CHECKED_ORDER = 'RESET_CHECKED_ORDER'

export const CHECKED_PLANNING = 'CHECKED_PLANNING'
export const CHECKED_VISIT = 'CHECKED_VISIT'

export const CHANGE_TOURS_TYPE_STATE = 'CHANGE_TOURS_TYPE_STATE'

export const UPDATE_COURSE_POLARIS = 'UPDATE_COURSE_POLARIS'
export const UPDATE_COURSE_POLARIS_SUCCESS = 'UPDATE_COURSE_POLARIS_SUCCESS'
export const UPDATE_COURSE_POLARIS_FAILURE = 'UPDATE_COURSE_POLARIS_FAILURE'

export const ACTION_UPDATE_COURSE_POLARIS = 'ACTION_UPDATE_COURSE_POLARIS'
export const RESET_SIMULATE_ACTION = 'RESET_SIMULATE_ACTION'

export const GET_TOURS_FROM_POLARIS = 'GET_TOURS_FROM_POLARIS'
export const GET_TOURS_FROM_POLARIS_SUCCESS = 'GET_TOURS_FROM_POLARIS_SUCCESS'
export const GET_TOURS_FROM_POLARIS_FAILURE = 'GET_TOURS_FROM_POLARIS_FAILURE'

export const DELETE_TOUR_FROM_POLARIS = 'DELETE_TOUR_FROM_POLARIS'
export const DELETE_TOUR_FROM_POLARIS_SUCCESS = 'DELETE_TOUR_FROM_POLARIS_SUCCESS'
export const DELETE_TOUR_FROM_POLARIS_FAILURE = 'DELETE_TOUR_FROM_POLARIS_FAILURE'

export const RESET_TOUR_ACTION = 'RESET_TOUR_ACTION'
export const ACTIVE_TOUR_ACTION = 'ACTIVE_TOUR_ACTION'

export const CHECKED_DRIVER = 'CHECKED_DRIVER'
export const OPTIMISATION_COURSES = 'OPTIMISATION_COURSES'
export const OPTIMISATION_COURSES_SUCCESS = 'OPTIMISATION_COURSES_SUCCESS'
export const OPTIMISATION_COURSES_FAILURE = 'OPTIMISATION_COURSES_FAILURE'

export const GET_WAREHOUSES = 'GET_WAREHOUSES'
export const GET_WAREHOUSES_SUCCESS = 'GET_WAREHOUSES_SUCCESS'
export const GET_WAREHOUSES_FAILURE = 'GET_WAREHOUSES_FAILURE'
export const GET_WAREHOUSES_MORE = 'GET_WAREHOUSES_MORE'
export const GET_WAREHOUSES_MORE_SUCCESS = 'GET_WAREHOUSES_MORE_SUCCESS'
export const GET_WAREHOUSES_MORE_FAILURE = 'GET_WAREHOUSES_MORE_FAILURE'

export const CHANGE_FILTER_OFFSET = 'CHANGE_FILTER_OFFSET'
export const SEARCH_WAHREHOUSES = 'SEARCH_WAHREHOUSES'
export const RESET_WAHREHOUSES = 'RESET_WAHREHOUSES'

export const UPDATE_COURSE_OPTIMISATION_FILE = 'UPDATE_COURSE_OPTIMISATION_FILE'
export const UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS =
  'UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS'
export const UPDATE_COURSE_OPTIMISATION_FILE_FAILURE =
  'UPDATE_COURSE_OPTIMISATION_FILE_FAILURE'

export const EXPORT_TOURS = 'EXPORT_TOURS'
export const EXPORT_TOURS_SUCCESS = 'EXPORT_TOURS_SUCCESS'
export const EXPORT_TOURS_FAILURE = 'EXPORT_TOURS_FAILURE'

export const FETCH_COURSES = 'FETCH_COURSES'

export const MAP_ADD_SELECTED_COURSES = 'MAP_ADD_SELECTED_COURSES'
export const MAP_REMOVE_SELECTED_COURSES = 'MAP_REMOVE_SELECTED_COURSES'
export const MAP_REMOVE_ALL_SELECTED_COURSES = 'MAP_REMOVE_ALL_SELECTED_COURSES'

export const VERROUILLE_TOURNEE = 'VERROUILLE_TOURNEE'
export const DEVERROUILLE_TOURNEE = 'DEVERROUILLE_TOURNEE'

export const CHANGE_STATUS_ADRESSE_MODIFICATION =
  'CHANGE_STATUS_ADRESSE_MODIFICATION'

export const CLOSE_DIALOG_REASON_UNSERVED = 'CLOSE_DIALOG_REASON_UNSERVED'
