import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  COURSE_STATUS,
  COURSE_STATUS_TERMINEE,
  COURSE_TYPE_CHANGE_STATUS,
  DEPART_ENTREPOT_CHANGE_STATUS,
  TERMINEE_STATUS,
} from '../../../../../../utils/values'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import NotInterestedIcon from '../../../../../../assets/icons/offIcon.svg'
import EquipageDialog from '../../../../../../containers/main/Optimiser'
import CancelShipment from '../../../../../../containers/main/ActionShipment/CancelShipment'
import { parseUnservedFromLegacy } from '../../../../../../utils/tours'
import Button from '@mui/material/Button'
import ClotureDialog from '../dialog/cloture'
import RetraitImpossibleIcon from '../../../../../../assets/icons/Cloture/retrait_impossible'
import TermineeIcon from '../../../../../../assets/icons/Cloture/terminee'
import moment from 'moment'
import { MOBILE_DRIVER } from '../../../../../../utils/values'
import {
  COURSE_SOURCE_DUPLICATED,
  COURSE_SOURCE_DUPLICATED_LIVRAISON_IMPOSSIBLE,
} from '../../../../../../utils/checkActionCourseSource'

const styles = {
  listItem: (color, backgroundColor) => ({
    color: color,
    padding: ' 0 3px',
    borderRadius: '8px',
    backgroundColor: backgroundColor,
    marginTop: '4px',
    marginBottom: '4px',
  }),
}
export default function StatusChange({
  order,
  onChangeStatus,
  updateOrdersArray,
  getMotifsCloture,
  isLoadingMotifListes,
  listesMotif,
  isLoading,
  isLoadingActionOrder,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [orderAnnuler, setOrderAnnuler] = useState({})
  const [payload, setPayload] = useState({})
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const [equipageDialogOpen, setEquipageDialogOpen] = useState(false)
  const [dropoffStartCourses, setDropoffStartCourses] = useState(false)
  const [equalDropoffStartCourses, setEqualDropoffStartCourses] = useState(false)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [shipments, setShipments] = useState([])
  const [clotureDialogOpen, setClotureDialogOpen] = useState(false)
  const [clotureObject, setClotureObject] = useState(false)

  const getClotureMotif = (status) => {
    const payload = {
      type: MOBILE_DRIVER,
      status: status,
    }
    getMotifsCloture(payload)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const changeStatusCourse = (status) => {
    let payload = {
      collaborateurId: connectedUser.id,
      state: status.code,
      codeCourse: order?.code,
      courseSource: order?.courseSource,
      driver:
        order.chauffeur != null && order?.chauffeur?.id
          ? { id: order.chauffeur.id }
          : undefined,
      method: 'corner/changementStatus',
      datetime: moment(new Date()).format(),
    }
    if (status.code === 'ANNULLEE') {
      setPayload(payload)
      setOrderAnnuler({
        shipmentReference: order?.lettreDeVoiture?.code,
        codeCourse: order?.code,
        courseSource: order?.courseSource,
      })
      setOpenCancelModal(true)
    } else if (status.code === 'ASSIGNED') {
      setSelectedCourses([order.code])
      setShipments([order])
      setDropoffStartCourses(order.dropOffStart)
      setEqualDropoffStartCourses(order?.dropOffStart)
      updateOrdersArray(parseUnservedFromLegacy([order], [order.code]))
      setEquipageDialogOpen(true)
    } else if (status.code === 'TERMINEE') {
      getClotureMotif(status.code)
      setPayload(payload)
      const Cloture = {
        icon: (
          <TermineeIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Terminer',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else if (status.code === 'LIVRAISON_IMPOSSIBLE') {
      // Ajouter order?.courseSource
      payload = {
        ...payload,
        duplicatedCourse: [
          ...COURSE_SOURCE_DUPLICATED,
          ...COURSE_SOURCE_DUPLICATED_LIVRAISON_IMPOSSIBLE,
        ].includes(order?.courseSource),
      }
      getClotureMotif(status.code)
      setPayload(payload)
      const Cloture = {
        icon: (
          <RetraitImpossibleIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Livraison impossible',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else if (status.code === 'RETRAIT_IMPOSSIBLE') {
      // Ajouter order?.courseSource
      payload = {
        ...payload,
        duplicatedCourse: COURSE_SOURCE_DUPLICATED.includes(order?.courseSource),
      }
      getClotureMotif(status.code)
      setPayload(payload)
      const Cloture = {
        icon: (
          <RetraitImpossibleIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Retrait impossible',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else if (status.code === 'ENLEVEE') {
      getClotureMotif(status.code)
      if (
        COURSE_TYPE_CHANGE_STATUS.includes(order?.courseMetadata?.courseType) &&
        order?.departureType == DEPART_ENTREPOT_CHANGE_STATUS
      ) {
        payload.state = 'TERMINEE'
      }
      setPayload(payload)
      const Cloture = {
        icon: (
          <TermineeIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Enlever',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else {
      onChangeStatus(payload)
    }
  }
  const onClotureCourse = (payloadChangeStatus) => {
    onChangeStatus({ ...payloadChangeStatus, ...payload })
  }
  return (
    <>
      {order?.status &&
        order?.status?.label &&
        order?.status?.code &&
        !isLoading && (
          <>
            {COURSE_STATUS[order.status.code] != undefined ? (
              <>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  sx={{
                    '&.MuiButton-root': {
                      width: '196px',
                      backgroundColor: '#1876d2',
                    },
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      textTransform: 'capitalize',
                    }}
                  >
                    <span
                      id={order.status.code}
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {order.status.code === 'VERS_ENLEVEMENT'
                        ? 'Vers retrait'
                        : order.status.code === 'VERS_DECHARGEMENT'
                        ? 'Vers livraison'
                        : order.status.label}
                    </span>
                    {COURSE_STATUS[order.status.code] != undefined &&
                      COURSE_STATUS[order.status.code].length > 0 && (
                        <>{anchorEl ? <ExpandLess /> : <ExpandMore />}</>
                      )}
                  </div>
                </Button>
                {((COURSE_STATUS[order.status.code] != undefined &&
                  COURSE_STATUS[order.status.code].length > 0) ||
                  (COURSE_TYPE_CHANGE_STATUS.includes(
                    order?.courseMetadata?.courseType
                  ) &&
                    order?.departureType == DEPART_ENTREPOT_CHANGE_STATUS &&
                    order.status.code === TERMINEE_STATUS.code)) && (
                  <Menu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{
                      '& .MuiMenu-paper': {
                        border: '1px solid #d3d4d5 !important',
                        width: '196px',
                      },
                    }}
                  >
                    {COURSE_TYPE_CHANGE_STATUS.includes(
                      order?.courseMetadata?.courseType
                    ) &&
                    order?.departureType == DEPART_ENTREPOT_CHANGE_STATUS &&
                    order.status.code === TERMINEE_STATUS.code
                      ? COURSE_STATUS_TERMINEE.map((status, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              changeStatusCourse(status)
                            }}
                            sx={{
                              '&.MuiMenuItem-root': {
                                '&:hover': {
                                  borderLeft: '2px solid #1976d2',
                                  '& .MuiListItemText-root': {
                                    marginLeft: '-2px',
                                  },
                                },
                                '& .MuiListItemText-root': {
                                  flex: 'none',
                                },
                              },
                            }}
                          >
                            <ListItemText
                              id={status.label}
                              primary={status.label}
                              style={styles.listItem(
                                status.color,
                                status.backgroundColor
                              )}
                            />
                          </MenuItem>
                        ))
                      : COURSE_STATUS[order.status.code].map((status, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              changeStatusCourse(status)
                            }}
                            sx={{
                              '&.MuiMenuItem-root': {
                                '&:hover': {
                                  borderLeft: '2px solid #1976d2',
                                  '& .MuiListItemText-root': {
                                    marginLeft: '-2px',
                                  },
                                },
                                '& .MuiListItemText-root': {
                                  flex: 'none',
                                },
                              },
                            }}
                          >
                            <ListItemText
                              id={status.label}
                              primary={status.label}
                              style={styles.listItem(
                                status.color,
                                status.backgroundColor
                              )}
                            />
                          </MenuItem>
                        ))}
                  </Menu>
                )}
              </>
            ) : (
              <div
                style={{
                  borderRadius: '4px',
                  boxShadow: '0 3px 3px 0 rgb(0 0 0 / 16%)',
                  backgroundColor: '#f0f0f0',
                  alignItems: 'center',
                  width: '160px',
                  display: 'flex',
                  padding: '6px 6px',
                }}
              >
                <img
                  src={NotInterestedIcon}
                  style={{ width: '16px', height: '16px' }}
                />
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#c9c9c9',
                    paddingLeft: '4px',
                  }}
                >
                  {order.status.label}
                </span>
              </div>
            )}
          </>
        )}

      {clotureDialogOpen && (
        <ClotureDialog
          open={clotureDialogOpen}
          handleClose={() => setClotureDialogOpen(false)}
          clotureObject={clotureObject}
          onClotureCourse={onClotureCourse}
          isLoadingMotifListes={isLoadingMotifListes}
          listesMotif={listesMotif}
          isLoadingActionOrder={isLoadingActionOrder}
        />
      )}
      {equipageDialogOpen && (
        <EquipageDialog
          open={equipageDialogOpen}
          hideDialog={() => setEquipageDialogOpen(false)}
          selectedCourses={selectedCourses}
          shipments={shipments}
          dropoffStartCourses={dropoffStartCourses}
          equalDropoffStartCourses={equalDropoffStartCourses}
        />
      )}
      {openCancelModal && (
        <CancelShipment
          open={openCancelModal}
          handleClose={() => setOpenCancelModal(false)}
          orderAnnuler={orderAnnuler}
          payload={payload}
        />
      )}
    </>
  )
}
StatusChange.propTypes = {
  order: PropTypes.object,
  onChangeStatus: PropTypes.func,
  setSimulateView: PropTypes.func,
  updateOrdersArray: PropTypes.any,
  getMotifsCloture: PropTypes.func,
  isLoadingMotifListes: PropTypes.bool,
  listesMotif: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadingActionOrder: PropTypes.bool,
}
