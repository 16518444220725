import React from 'react'
import PropTypes from 'prop-types'

import { getCreneauHoursMinutes, getCourseType } from '../../../../../../utils/utils'
import { parseReceptionNumber } from '../../../../../../utils/tours'
import { StyledCell } from './style'
import TextComponent from './texComponent'
const dataRowContent = (row, rowNumber, isOptimisationCoursesFiles) => {
  return {
    index: rowNumber + 1,
    pickup:
      row?.pickUpStart && row?.pickUpEnd
        ? getCreneauHoursMinutes(row.pickUpStart, row.pickUpEnd, '~')
        : '-',
    dropoff:
      row.dropOffStart && row.dropOffEnd
        ? getCreneauHoursMinutes(row.dropOffStart, row.dropOffEnd, '~')
        : '-',
    courseType:
      isOptimisationCoursesFiles && row?.courseType
        ? row.courseType
        : row?.courseType
        ? getCourseType(row.courseType)
        : '-',
    endLocation: row.endLocation?.name ? row.endLocation?.name : '-',
    volume: row?.volume >= 0 && row?.volume != null ? row?.volume + '' : '-',
    weight: row?.weight >= 0 && row?.weight != null ? row?.weight + '' : '-',
    price:
      row?.montantPrestaHT >= 0 && row?.montantPrestaHT != null
        ? row?.montantPrestaHT
        : '-',
    reason: row?.reason ? row?.reason : '-',
    ldv: row?.ldv ? row?.ldv : '-',
    salesChannel: row?.salesChanel?.name ? row?.salesChanel.name : '-',
    receptionNumber: row?.id ? parseReceptionNumber(row?.id) : '-',
    creneauArivee1:
      row?.dropOffStart && row?.dropOffEnd
        ? getCreneauHoursMinutes(row?.dropOffStart, row?.dropOffEnd, '~')
        : '-- : --',
    creneauArivee2:
      row?.secondDropOffStart && row?.secondDropOffEnd
        ? getCreneauHoursMinutes(row?.secondDropOffStart, row?.secondDropOffEnd, '~')
        : '-- : --',
    nbColis: row?.nbColis >= 0 && row?.nbColis != null ? row?.nbColis : '-',
    prestaTime:
      row?.prestaTime >= 0 && row?.prestaTime != null ? row?.prestaTime : '-',
    codePostale: row?.codePostale ? row?.codePostale : '-',
    keys: row?.keys && Array.isArray(row.keys) ? row?.keys.join(', ') : '-',
    client: row?.client ? row?.client : '-',
    orderGiver: row?.orderGiver ? row?.orderGiver : '-',
    city: row?.city ? row?.city : '-',
  }
}
const TableRowsContent = ({
  row,
  rowNumber,
  isOptimisationCoursesFiles,
  columnsList,
  disabledColumn,
  isChecked,
  withReason,
}) => {
  return (
    <>
      {columnsList.map((el, index) => {
        return (
          <StyledCell
            align="center"
            id={`item-Courses-${rowNumber}-${el.id}`}
            key={index}
            sx={{
              background: isChecked
                ? 'rgba(0, 0, 0, 0.20)'
                : disabledColumn
                ? '#F1F1F1'
                : withReason
                ? '#ffca8a'
                : 'none',
              color:
                disabledColumn && el.id == 'endLocation'
                  ? '#D50000'
                  : disabledColumn && '#8E8E8E',
            }}
          >
            <TextComponent
              content={
                dataRowContent(row, rowNumber, isOptimisationCoursesFiles)[el.id]
              }
              key={index}
              isDisabledAddress={disabledColumn && el.id == 'endLocation'}
            />
          </StyledCell>
        )
      })}
    </>
  )
}
TableRowsContent.propTypes = {
  row: PropTypes.object,
  rowNumber: PropTypes.number,
  isOptimisationCoursesFiles: PropTypes.bool,
  columnsList: PropTypes.array,
  disabledColumn: PropTypes.bool,
  isChecked: PropTypes.bool,
  withReason: PropTypes.bool,
}

export default TableRowsContent
