import _ from 'lodash'

import initialState from './initialState'
import {
  SIMULATE_TOURS,
  SIMULATE_TOURS_FAILURE,
  SIMULATE_TOURS_SUCCESS,
  CREATE_TOURS,
  CREATE_TOURS_FAILURE,
  CREATE_TOURS_SUCCESS,
  UPDATE_FOCUSED_TOURS,
  RECALCULATE_TOURS_FAILURE,
  RECALCULATE_TOURS_SUCCESS,
  RECALCULATE_TOURS,
  UPDATE_ORDERS_ARRAY,
  GET_DRIVERS_PLANNINGS,
  GET_DRIVERS_PLANNINGS_SUCCESS,
  GET_DRIVERS_PLANNINGS_FAILURE,
  AFFECT_TOUR,
  AFFECT_TOUR_SUCCESS,
  AFFECT_TOUR_FAILURE,
  REMOVE_ERROR_PLANNIFICATION,
  CHECKED_ALL_ORDERS,
  CHECKED_ORDER,
  RESET_CHECKED_ORDER,
  CHECKED_PLANNING,
  CHECKED_VISIT,
  CHANGE_TOURS_TYPE_STATE,
  VIEW_PLANNIFICATION,
  UPDATE_COURSE_POLARIS,
  UPDATE_COURSE_POLARIS_SUCCESS,
  UPDATE_COURSE_POLARIS_FAILURE,
  ACTION_UPDATE_COURSE_POLARIS,
  RESET_SIMULATE_ACTION,
  GET_TOURS_FROM_POLARIS,
  GET_TOURS_FROM_POLARIS_SUCCESS,
  GET_TOURS_FROM_POLARIS_FAILURE,
  DELETE_TOUR_FROM_POLARIS,
  DELETE_TOUR_FROM_POLARIS_SUCCESS,
  DELETE_TOUR_FROM_POLARIS_FAILURE,
  RESET_TOUR_ACTION,
  ACTIVE_TOUR_ACTION,
  OPTIMISATION_COURSES,
  OPTIMISATION_COURSES_SUCCESS,
  OPTIMISATION_COURSES_FAILURE,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,
  GET_WAREHOUSES_MORE_SUCCESS,
  GET_WAREHOUSES_MORE_FAILURE,
  CHANGE_FILTER_OFFSET,
  SEARCH_WAHREHOUSES,
  RESET_WAHREHOUSES,
  UPDATE_COURSE_OPTIMISATION_FILE,
  UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS,
  UPDATE_COURSE_OPTIMISATION_FILE_FAILURE,
  EXPORT_TOURS,
  EXPORT_TOURS_SUCCESS,
  EXPORT_TOURS_FAILURE,
  FETCH_COURSES,
  MAP_ADD_SELECTED_COURSES,
  MAP_REMOVE_SELECTED_COURSES,
  MAP_REMOVE_ALL_SELECTED_COURSES,
  VERROUILLE_TOURNEE,
  DEVERROUILLE_TOURNEE,
  CHANGE_STATUS_ADRESSE_MODIFICATION,
  CLOSE_DIALOG_REASON_UNSERVED,
} from './actionsConsts'

import {
  getRoutificMessageError,
  getPlanningData,
  getToursData,
  newPlannings,
  fleetParseObject,
  parseFilterTournee,
  parseUnservedFromFile,
  replaceInArrayUpdateFromLegacy,
  replaceInArrayUpdateFromPolaris,
  deleteFromUnserved,
  deleteDuplicatedCourses,
  updateTourRecChecked,
} from '../../utils/tours'
import {
  fetchCoursesWithFilter,
  getFilterfromCourse,
  removeNull,
} from '../../utils/utils'
import { ACCURATE_ADDRESS } from '../../utils/values'
export const LDV_TYPE = 'LDV'

export default (state = initialState, action) => {
  //Get Tours by drivers
  if (action.type === GET_DRIVERS_PLANNINGS) {
    const { isNewTour } = action.payload.data
    return {
      ...state,
      isLoading: true,
      tours: [],
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: isNewTour ? false : true,
      tourAction: false,
    }
  }

  if (action.type === GET_DRIVERS_PLANNINGS_SUCCESS) {
    const drivers = action.payload.data.secData.drivers
    const tours = newPlannings(
      getPlanningData(
        action.payload.data.data,
        action.payload.data.secData.deliveryDate
      ),
      drivers,
      action.payload.data.secData.deliveryDate
    )

    const idsDelete = tours.flatMap((tour) =>
      tour.markers.map((marker) => marker.location_id)
    )
    const newUnservedOrders = deleteFromUnserved(state.unservedOrders, idsDelete)

    const tourType = []
    tours.forEach((tour) => tourType.push(tour.tourType))
    // check if driver havn't empty tours
    // check if tourTypes is all the same
    if (tourType.filter((obj) => obj).some((v, i, arr) => v != arr[0])) {
      return {
        ...state,
        isLoading: false,
        tours: [],
        errorMessage: {
          message:
            'Vous avez choisis des tournées avec différent type, Merci de vérifier',
        },
        isErrorAffectPlanning: true,
      }
    }
    return {
      ...state,
      isLoading: false,
      tours: tours,
      focusedTours: [],
      fleet: action.payload.data.secData,
      deliveryDate: action.payload.data.secData.deliveryDate,
      errorMessage: '',
      unservedOrders: newUnservedOrders,
      unservedOrdersOriginal: newUnservedOrders,
      isErrorAffectPlanning: false,
      isOnePickUp:
        tours.length > 0
          ? tours[0]?.tourType === 'ONE_PICK_UP'
            ? true
            : false
          : false,
    }
  }

  if (action.type === GET_DRIVERS_PLANNINGS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isErrorAffectPlanning: true,
    }
  }

  //Affect Tous to Drivers
  if (action.type === AFFECT_TOUR) {
    return {
      ...state,
      isLoading: true,
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: true,
      tourAction: false,
    }
  }

  if (action.type === AFFECT_TOUR_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      tourAction: true,
      fileNameCoursesOptimisation: '',
    }
  }

  if (action.type === AFFECT_TOUR_FAILURE) {
    return {
      ...state,
      isLoading: false,
      isErrorAffectPlanning: true,
      errorMessage: { message: "une erreur s'est produite" },
      tourAction: false,
    }
  }

  // recalculate tours
  if (action.type === RECALCULATE_TOURS) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === RECALCULATE_TOURS_SUCCESS) {
    const {
      secData: { isUnservedChanged, newUnservedTours },
      data,
    } = action.payload.data

    // send tables planning to recalculate and return result and parse it via getTourData
    const changedTours = Object.assign([{ tours: data }])
    const driverTours = [...state.tours.flat()].map((s) => {
      return { driverId: s.driver.id, ldt: s.ldt }
    })

    const changedToursParsed = getToursData(
      changedTours,
      state.deliveryDate,
      driverTours
    )

    let newTours = _.cloneDeep(state.tours)

    changedToursParsed.flat(1).map((changedtour) => {
      const tourChangedIndex = state.tours
        .map((el) => el.driver.id)
        .indexOf(changedtour.driver.id)
      newTours[tourChangedIndex] = changedtour
    })

    let payload = {}

    if (isUnservedChanged != undefined) {
      //Save unserved courses
      let newUnservedOrdersOriginal =
        newUnservedTours.length > 0
          ? deleteDuplicatedCourses(state.unservedOrdersOriginal, newUnservedTours)
          : [...state.unservedOrdersOriginal]

      let newUnservedOrders =
        newUnservedTours.length > 0 ? [...newUnservedTours] : []

      let newSelectedCourses = state.selectedCourses
      //Supprimer idCourse depuis selectedCourses lors de deplacer courses depuis table unserved à table tournée
      if (isUnservedChanged == false) {
        const idsDelete = newTours.flatMap((tour) =>
          tour.markers.map((marker) => marker.location_id)
        )
        newUnservedOrdersOriginal = deleteFromUnserved(
          state.unservedOrdersOriginal,
          idsDelete
        )
        newSelectedCourses = state.selectedCourses.filter(
          (idCourses) => !idsDelete.includes(idCourses)
        )
      }
      //Vérifier si existe courses à des reasons
      const isUnservedReturnedReason = newUnservedOrdersOriginal.some(
        (el) => el?.reason
      )

      if (state.isOptimisationCoursesFiles) {
        let newUnservedOrdersFiltree = []

        if (isUnservedChanged == true) {
          newUnservedOrdersFiltree = fetchCoursesWithFilter(
            newUnservedOrdersOriginal,
            state.filterCourses
          )
          payload = {
            unservedOrdersOriginal: newUnservedOrdersOriginal,
            unservedOrders: newUnservedOrdersFiltree,
            selectedCourses: [...newSelectedCourses],
            isUnservedReturnedReason: isUnservedReturnedReason,
          }
        } else {
          let filterCourses = state.filterCourses
          let keys = Object.keys(filterCourses)
          while (
            keys.length > 0 &&
            newUnservedOrdersFiltree.length == 0 &&
            newUnservedOrders.length == 0
          ) {
            const lastKey = keys.pop()
            delete filterCourses[lastKey]
            newUnservedOrdersFiltree = fetchCoursesWithFilter(
              newUnservedOrdersOriginal,
              filterCourses
            )
          }
          const Filters = getFilterfromCourse(
            newUnservedOrders.length > 0
              ? newUnservedOrders
              : keys.length > 0
              ? newUnservedOrdersFiltree
              : newUnservedOrdersOriginal
          )
          const filterCourseFile = parseFilterTournee(Filters)
          payload = {
            filterCourses: { ...filterCourses },
            filterCourseFile: { ...filterCourseFile },
            unservedOrdersOriginal: newUnservedOrdersOriginal,
            unservedOrders:
              newUnservedOrders.length > 0
                ? newUnservedOrders
                : keys.length > 0
                ? newUnservedOrdersFiltree
                : newUnservedOrdersOriginal,
            selectedCourses: [...newSelectedCourses],
            isUnservedReturnedReason: isUnservedReturnedReason,
          }
        }
      } else {
        payload = {
          unservedOrdersOriginal: newUnservedOrdersOriginal,
          unservedOrders: newUnservedOrders,
          selectedCourses: [...newSelectedCourses],
          isUnservedReturnedReason: isUnservedReturnedReason,
        }
      }
    }
    return {
      ...state,
      ...payload,
      tours: newTours,
      isLoading: false,
      tourRecChecked: {},
    }
  }

  if (action.type === RECALCULATE_TOURS_FAILURE) {
    return {
      ...state,
      errorMessage: {
        message: action.payload.data.response?.data.message
          ? action.payload.data.response?.data.message
          : 'Probléme Serveur, veuillez réessayer plus tard',
      },
      isError: true,
      isLoading: false,
      selectedCourses: [],
    }
  }

  //optimiser tours
  if (action.type === SIMULATE_TOURS) {
    return {
      ...state,
      isLoading: true,
      oldTours: [...state.tours],
      tours: [],
      errorMessage: '',
      isError: false,
    }
  }

  if (action.type === SIMULATE_TOURS_SUCCESS) {
    const driverTours = [...state.oldTours].map((s) => {
      return { driverId: s.driver.id, ldt: s.ldt }
    })

    const tours = getToursData(action.payload.data, state.deliveryDate, driverTours)

    let unservedOrdersReturned = action.payload.data[0].newUnserved
    // V2 : the optimisation is applied on the chosen drivers
    let toursV2 = state.oldTours

    // we process to merge optimised tours on the chosen drivers with non optimised (unchosen drivers)
    tours[0].forEach((tour) => {
      const indexTourToChange = state.oldTours.findIndex(
        (oldTour) => oldTour.driver.id === tour.driver.id
      )

      if (indexTourToChange !== -1) {
        toursV2[indexTourToChange] = tour
      }
    })

    let newUnservedOrdersTab = [...state.unservedOrders]
    let unservedOrdersOriginalTab = [...state.unservedOrdersOriginal]

    //Eliminer la duplication lors de courses retournée par MS
    if (unservedOrdersReturned.length > 0) {
      newUnservedOrdersTab = deleteDuplicatedCourses(
        newUnservedOrdersTab,
        unservedOrdersReturned
      )
      unservedOrdersOriginalTab = deleteDuplicatedCourses(
        unservedOrdersOriginalTab,
        unservedOrdersReturned
      )
    }

    const idsDelete = toursV2.flatMap((tour) =>
      tour.markers.map((marker) => marker.location_id)
    )

    let newUnservedOrdersOriginal = deleteFromUnserved(
      unservedOrdersOriginalTab,
      idsDelete
    )
    const isUnservedReturnedReason = newUnservedOrdersOriginal.some(
      (el) => el?.reason
    )
    let newUnservedOrders = deleteFromUnserved(newUnservedOrdersTab, idsDelete)

    if (state.isOptimisationCoursesFiles) {
      let filterCourses = state.filterCourses

      const keys = Object.keys(filterCourses)

      let newUnservedOrdersFiltree = []
      while (
        keys.length > 0 &&
        newUnservedOrdersFiltree.length == 0 &&
        newUnservedOrders.length == 0
      ) {
        const lastKey = keys.pop()
        delete filterCourses[lastKey]
        newUnservedOrdersFiltree = fetchCoursesWithFilter(
          newUnservedOrdersOriginal,
          filterCourses
        )
      }

      const Filters = getFilterfromCourse(
        newUnservedOrders.length > 0
          ? newUnservedOrders
          : keys.length > 0
          ? newUnservedOrdersFiltree
          : newUnservedOrdersOriginal
      )
      const filterCourseFile = parseFilterTournee(Filters)

      return {
        ...state,
        isLoading: false,
        tours: toursV2,
        oldTours: [],
        unservedOrders:
          newUnservedOrders.length > 0
            ? newUnservedOrders
            : keys.length > 0
            ? newUnservedOrdersFiltree
            : newUnservedOrdersOriginal,
        unservedOrdersOriginal: newUnservedOrdersOriginal,
        selectedCourses: [],
        filterCourseFile: { ...filterCourseFile },
        tourRecChecked: {},
        filterCourses: { ...filterCourses },
        isUnservedReturnedReason: isUnservedReturnedReason,
      }
    }

    return {
      ...state,
      isLoading: false,
      tours: toursV2,
      oldTours: [],
      unservedOrders: newUnservedOrders,
      unservedOrdersOriginal: newUnservedOrdersOriginal,
      selectedCourses: [],
      tourRecChecked: {},
      isUnservedReturnedReason: isUnservedReturnedReason,
    }
  }
  if (action.type === SIMULATE_TOURS_FAILURE) {
    const helperCode = action.payload.data?.response?.data?.errorHelperCode
    const helperVar = action.payload.data?.response?.data?.errorHelperVar

    return {
      ...state,
      isLoading: false,
      tours: state.oldTours,
      errorMessage: action.payload.data.response?.data?.errorHelperCode
        ? getRoutificMessageError(helperCode, helperVar)
        : {
            message: action.payload.data.response?.data.message
              ? action.payload.data.response?.data.message
              : 'Probléme serveur, veuillez réessayer plus tard',
          },
      isError: true,
    }
  }

  // Sauvegarder tours
  if (action.type === CREATE_TOURS) {
    return {
      ...state,
      isLoading: true,
      tourAction: false,
    }
  }
  if (action.type === CREATE_TOURS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
      tourAction: false,
    }
  }
  if (action.type === CREATE_TOURS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: '',
      isError: false,
      unservedOrders: [],
      unservedOrdersOriginal: [],
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      tourAction: true,
      fileNameCoursesOptimisation: '',
    }
  }

  // view plannfication
  if (action.type === VIEW_PLANNIFICATION) {
    const { viewPlannification } = action.payload
    if (!viewPlannification) {
      return {
        ...state,
        isViewPlannification: false,
        isOptimisationCoursesFiles: false,
        filterCourseFile: {},
        fileNameCoursesOptimisation: '',
      }
    }
    return {
      ...state,
      isViewPlannification: viewPlannification,
    }
  }

  //Remove Error
  if (action.type === REMOVE_ERROR_PLANNIFICATION) {
    const { isViewPlannification } = action.payload
    if (!isViewPlannification) {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        isError: false,
        isErrorAffectPlanning: false,
        isViewPlannification: false,
        isOptimisationCoursesFiles: false,
        filterCourseFile: {},
        fileNameCoursesOptimisation: '',
      }
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      isError: false,
      isErrorAffectPlanning: false,
      isViewPlannification,
    }
  }

  if (action.type === UPDATE_FOCUSED_TOURS) {
    const { deselection, id } = action.payload
    const focused = state.focusedTours
    let newList = []
    if (!deselection) {
      const selectedIndex = focused.indexOf(id)

      if (selectedIndex === -1) {
        newList = newList.concat(focused, id)
      } else if (selectedIndex === 0) {
        newList = newList.concat(focused.slice(1))
      } else if (selectedIndex === focused.length - 1) {
        newList = newList.concat(focused.slice(0, -1))
      } else if (selectedIndex > 0) {
        newList = newList.concat(
          focused.slice(0, selectedIndex),
          focused.slice(selectedIndex + 1)
        )
      }
    }

    return {
      ...state,
      focusedTours: newList,
    }
  }
  if (action.type === CHANGE_TOURS_TYPE_STATE) {
    const { isOnePickUp } = action.payload
    return {
      ...state,
      isOnePickUp,
    }
  }

  if (action.type === UPDATE_ORDERS_ARRAY) {
    return {
      ...state,
      unservedOrders: action.payload.data,
      tourRecChecked: {},
    }
  }

  if (action.type === CHECKED_ALL_ORDERS) {
    const { checkedAll, isDialogCoursesReason } = action.payload
    let newSelected = []
    if (checkedAll) {
      const courses = isDialogCoursesReason
        ? state.unservedOrdersOriginal.filter((course) => course?.reason)
        : state.unservedOrders.filter(
            (course) =>
              !(
                state.isOptimisationCoursesFiles &&
                !ACCURATE_ADDRESS.includes(course.locationType)
              )
          )

      newSelected = courses.map(({ id }) => id)
    }
    return {
      ...state,
      selectedCourses: newSelected,
    }
  }

  if (action.type === CHECKED_ORDER) {
    const id = action.payload

    const selectedCourses = [...state.selectedCourses]

    const selectedIndex = selectedCourses.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCourses, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCourses.slice(1))
    } else if (selectedIndex === selectedCourses.length - 1) {
      newSelected = newSelected.concat(selectedCourses.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCourses.slice(0, selectedIndex),
        selectedCourses.slice(selectedIndex + 1)
      )
    }
    return {
      ...state,
      selectedCourses: newSelected,
    }
  }

  if (action.type === RESET_CHECKED_ORDER) {
    return {
      ...state,
      selectedCourses: [],
    }
  }

  if (action.type === CHECKED_PLANNING) {
    const { indexPlanning, checkedAll } = action.payload

    let tourRecChecked = state.tourRecChecked

    state.tours[indexPlanning].markers.map((visit) => {
      if (checkedAll) {
        delete tourRecChecked[visit.receptionNumber]
      } else {
        if (
          visit.type == 'dropoff' ||
          (visit.type == 'pickup' && !state.isOptimisationCoursesFiles)
        ) {
          const entry = {}
          entry[visit.type] = true
          tourRecChecked[visit.receptionNumber] = entry
        }
      }
    })

    return {
      ...state,
      tourRecChecked: { ...tourRecChecked },
    }
  }

  if (action.type === CHECKED_VISIT) {
    const { receptionNumber, type } = action.payload
    // const { receptionNumber, type } = action.payload
    let tourRecChecked = updateTourRecChecked(
      state.tourRecChecked,
      receptionNumber,
      type
    )

    return {
      ...state,
      tourRecChecked: { ...tourRecChecked },
    }
  }

  if (action.type === UPDATE_COURSE_POLARIS) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: true,
      isUpdateCoursePolarisAction: false,
      errorMessage: '',
      isError: false,
    }
  }
  if (action.type === UPDATE_COURSE_POLARIS_SUCCESS) {
    const { response } = action.payload.data

    let newUnservedOrders = state.unservedOrders.reduce(
      (a, v) => ({ ...a, [v.id]: v }),
      {}
    )

    let newUnservedOrdersOriginal = state.unservedOrdersOriginal.reduce(
      (a, v) => ({ ...a, [v.id]: v }),
      {}
    )

    if (response.length > 0) {
      response.forEach((newCourse) => {
        newUnservedOrders[newCourse.codeCourse] = replaceInArrayUpdateFromLegacy(
          newUnservedOrders,
          newCourse
        )
        newUnservedOrdersOriginal[newCourse.codeCourse] =
          replaceInArrayUpdateFromLegacy(newUnservedOrdersOriginal, newCourse)
      })
    }
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      isUpdateCoursePolarisAction: true,
      unservedOrders: [...Object.values(newUnservedOrders)],
      unservedOrdersOriginal: [...Object.values(newUnservedOrdersOriginal)],
      selectedCourses: [],
    }
  }
  if (action.type === UPDATE_COURSE_POLARIS_FAILURE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
    }
  }
  if (action.type === ACTION_UPDATE_COURSE_POLARIS) {
    return {
      ...state,
      isUpdateCoursePolarisAction: false,
    }
  }
  if (action.type === RESET_SIMULATE_ACTION) {
    return {
      ...state,
      tourAction: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS) {
    return {
      ...state,
      isError: false,
      isLoading: true,
      tourAction: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      tourList: data,
      countTours: count,
      isError: false,
      isLoading: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS_FAILURE) {
    const message = action.payload.data?.response.message
    return {
      ...state,
      isError: true,
      errorMessage: message || 'Probléme serveur, veuillez réessayer plus tard',
      isLoading: false,
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS) {
    return {
      ...state,
      isLoading: true,
      tourAction: false,
      errorMessage: '',
      isViewPlannification: true,
      isErrorAffectPlanning: false,
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS_SUCCESS) {
    return {
      ...state,
      tourAction: true,
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      fileNameCoursesOptimisation: '',
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS_FAILURE) {
    const message = action.payload.data?.response?.message
    return {
      ...state,
      isErrorAffectPlanning: true,
      errorMessage: { message: message || "une erreur s'est produite" },
      isLoading: false,
      tourAction: false,
    }
  }

  if (action.type === ACTIVE_TOUR_ACTION) {
    return {
      ...state,
      tourAction: true,
    }
  }
  if (action.type === RESET_TOUR_ACTION) {
    return {
      ...state,
      tourAction: false,
    }
  }

  //optimisation course Euromatic
  if (action.type === OPTIMISATION_COURSES) {
    return {
      ...state,
      isLoading: true,
      tours: [],
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: true,
      isOptimisationCoursesFiles: true,
      tourAction: false,
      tourRecChecked: {},
      focusedTours: [],
      fleet: [],
      unservedOrders: [],
      unservedOrdersOriginal: [],
      selectedCourses: [],
      filterCourseFile: {},
      filterCourses: {},
    }
  }

  if (action.type === OPTIMISATION_COURSES_SUCCESS) {
    const { courses, tours, drivers, deliveryDate, fileName } = action.payload.data
    const objectFleet = fleetParseObject(drivers, deliveryDate)
    const toursPlanning = newPlannings(
      getPlanningData(tours, deliveryDate),
      objectFleet.drivers,
      deliveryDate
    )
    const newUnservedOrdersParse = parseUnservedFromFile(courses)
    const Filters = getFilterfromCourse(newUnservedOrdersParse)
    const filterCourseFile = parseFilterTournee(Filters)

    const openDialogModificationUnservedOrders = newUnservedOrdersParse.some(
      (course) => !ACCURATE_ADDRESS.includes(course.locationType)
    )
    return {
      ...state,
      isLoading: false,
      tours: toursPlanning,
      fleet: objectFleet,
      deliveryDate: deliveryDate,
      errorMessage: '',
      unservedOrders: newUnservedOrdersParse,
      unservedOrdersOriginal: newUnservedOrdersParse,
      isErrorAffectPlanning: false,
      isOnePickUp: true,
      filterCourseFile: { ...filterCourseFile },
      filterCourses: {},
      fileNameCoursesOptimisation: fileName,
      isModifAdresse: openDialogModificationUnservedOrders,
    }
  }

  if (action.type === OPTIMISATION_COURSES_FAILURE) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: {
        message:
          action.payload?.data?.response?.data?.message ||
          'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isErrorAffectPlanning: true,
    }
  }

  // Get Wahrehouse ALL
  if (action.type === GET_WAREHOUSES) {
    return {
      ...state,
      isLoadingWarehouses: true,
    }
  }

  if (action.type === GET_WAREHOUSES_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      isLoadingWarehouses: false,
      warehouses: data,
      warehousesCount: count,
    }
  }

  if (action.type === GET_WAREHOUSES_MORE_SUCCESS) {
    return {
      ...state,
      warehouses: [...state.warehouses, ...action.payload?.data?.data],
      warehousesCount: action.payload.data?.count || 0,
    }
  }

  if (
    action.type === GET_WAREHOUSES_FAILURE ||
    action.type === GET_WAREHOUSES_MORE_FAILURE
  ) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingWarehouses: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === CHANGE_FILTER_OFFSET) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === SEARCH_WAHREHOUSES) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        limit: 25,
        offset: 0,
        query: search,
      },
    }
  }

  if (action.type === RESET_WAHREHOUSES) {
    //rest
    return {
      ...state,
      isLoadingWarehouses: false,
      warehouses: [],
      warehousesCount: 0,
      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: true,
      isUpdateCoursePolarisAction: false,
      errorMessage: '',
      isError: false,
    }
  }
  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS) {
    const { courses } = action.payload.data
    let newUnservedOrders = state.unservedOrders.reduce(
      (a, v) => ({ ...a, [v.id]: v }),
      {}
    )
    let newUnservedOrdersOriginal = state.unservedOrdersOriginal.reduce(
      (a, v) => ({ ...a, [v.id]: v }),
      {}
    )
    if (courses.length > 0) {
      courses.forEach((newCourse) => {
        newUnservedOrders[newCourse.receptionNumber] =
          replaceInArrayUpdateFromPolaris(newUnservedOrders, newCourse)
        newUnservedOrdersOriginal[newCourse.receptionNumber] =
          replaceInArrayUpdateFromPolaris(newUnservedOrdersOriginal, newCourse)
      })
    }

    const newUnservedOrdersFiltre = fetchCoursesWithFilter(
      [...Object.values(newUnservedOrders)],
      state.filterCourses
    )
    const Filters = getFilterfromCourse(newUnservedOrdersFiltre)
    const filterCourseFile = parseFilterTournee(Filters)

    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      isUpdateCoursePolarisAction: true,
      unservedOrders: [...newUnservedOrdersFiltre],
      unservedOrdersOriginal: [...Object.values(newUnservedOrdersOriginal)],
      filterCourseFile: { ...filterCourseFile },
      selectedCourses: [],
    }
  }
  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE_FAILURE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
    }
  }

  // export tours
  if (action.type === EXPORT_TOURS) {
    const { isDownload } = action.payload
    return {
      ...state,
      isDownload: isDownload,
      isLoading: !isDownload,
      tourAction: false,
    }
  }
  if (action.type === EXPORT_TOURS_SUCCESS) {
    const { data } = action.payload
    const { buffer, fileName, contentType } = data
    const parsedBuffer = new Uint8Array(buffer.data)
    const fileData = new Blob([parsedBuffer], { type: contentType })
    // Create a download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(fileData)
    downloadLink.download = fileName // Set the desired filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    const { isDownload } = state
    return {
      ...state,
      isLoading: false,
      ...(isDownload
        ? { isOptimisationCoursesFiles: true }
        : {
            tours: [],
            unservedOrders: [],
            isViewPlannification: false,
            tourAction: true,
            isOptimisationCoursesFiles: false,
          }),
      errorMessage: '',
      isError: false,
      isModifAdresse: false,
    }
  }
  if (action.type === EXPORT_TOURS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
      tourAction: false,
    }
  }

  if (action.type === FETCH_COURSES) {
    const { filter } = action.payload
    let newUnservedOrders = []

    if (
      Object.keys(removeNull(filter)).length != 0 &&
      state.unservedOrdersOriginal &&
      state.unservedOrdersOriginal.length > 0
    ) {
      newUnservedOrders = fetchCoursesWithFilter(
        state.unservedOrdersOriginal,
        filter
      )
    } else {
      newUnservedOrders = state.unservedOrdersOriginal
    }
    let newCourses =
      newUnservedOrders.length > 0 ? newUnservedOrders : state.unservedOrdersOriginal
    const Filters = getFilterfromCourse(newCourses)
    const filterCourseFile = parseFilterTournee(Filters)
    return {
      ...state,
      unservedOrders: newUnservedOrders,
      filterCourses: { ...filter },
      filterCourseFile: { ...filterCourseFile },
    }
  }
  //Selected Course (MAP)
  if (action.type === MAP_ADD_SELECTED_COURSES) {
    const courses = action.payload.data.data

    const newSelectedCourses = [...new Set([...state.selectedCourses, ...courses])]
    return {
      ...state,
      selectedCourses: newSelectedCourses,
    }
  }

  if (action.type === MAP_REMOVE_SELECTED_COURSES) {
    const courses = action.payload.data.data

    const newSelectedCourses = [...state.selectedCourses].filter(
      (x) => !courses.includes(x)
    )

    return {
      ...state,
      selectedCourses: newSelectedCourses,
    }
  }

  if (action.type === MAP_REMOVE_ALL_SELECTED_COURSES) {
    return {
      ...state,
      selectedCourses: [],
    }
  }

  if (action.type === VERROUILLE_TOURNEE) {
    const { positionsChauffeur } = action.payload

    const newTours = state.tours
    positionsChauffeur.map((position) => {
      newTours[position].lock = true
    })
    return {
      ...state,
      tours: [...newTours],
    }
  }

  if (action.type === DEVERROUILLE_TOURNEE) {
    const { positionsChauffeur } = action.payload

    const newTours = state.tours
    positionsChauffeur.map((position) => {
      newTours[position].lock = false
    })
    return {
      ...state,
      tours: [...newTours],
    }
  }

  if (action.type === CHANGE_STATUS_ADRESSE_MODIFICATION) {
    return {
      ...state,
      isModifAdresse: false,
    }
  }

  if (action.type === CLOSE_DIALOG_REASON_UNSERVED) {
    return {
      ...state,
      isUnservedReturnedReason: false,
      unservedOrders: state.unservedOrders.map((el) => {
        return { ...el, reason: undefined }
      }),
      unservedOrdersOriginal: state.unservedOrdersOriginal.map((el) => {
        return { ...el, reason: undefined }
      }),
      selectedCourses: [],
    }
  }

  return state
}
