import { connect } from 'react-redux'

import {
  fetchEtiquetteList,
  fetchEtiquetteListMore,
  generationEtiquette,
  generationQRcode,
  generationTournee,
} from '../../../../redux/documents/actions'

import {
  getIsLoadingEtiquette,
  getEtiquettes,
  getCountetiquettes,
} from '../../../../redux/documents/selectors'

import SearchDownloadDocument from '../../../../components/NavFilter/components/searchCorner/searchDownloadDocument'
import {
  fetchDriversWarrning,
  fetchDriversWarrningMoreScroll,
} from '../../../../redux/driver/actions'
import {
  getDrivers,
  getDriversOptimiserCount,
  getDriversOptimiserIsLoading,
} from '../../../../redux/driver/selectors'
import {
  getCountListTour,
  getIsTourListLoading,
  getTourFilterList,
} from '../../../../redux/filter/selectors'
import { getTourList, getTourListMore } from '../../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  etiquettesList: getEtiquettes(state),
  isLoadingEtiquette: getIsLoadingEtiquette(state),
  etiquettesCount: getCountetiquettes(state),
  drivers: getDrivers(state),
  isLoadingDrivers: getDriversOptimiserIsLoading(state),
  driversCount: getDriversOptimiserCount(state),
  tourList: getTourFilterList(state),
  tourListLoading: getIsTourListLoading(state),
  countListTour: getCountListTour(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchEtiquetteList: (filter) => {
    dispatch(fetchEtiquetteList(filter))
  },
  fetchEtiquetteListMore: (filter) => {
    dispatch(fetchEtiquetteListMore(filter))
  },

  fetchDrivers: (filter) => {
    dispatch(fetchDriversWarrning(filter))
  },
  fetchDriversMore: (filter) => dispatch(fetchDriversWarrningMoreScroll(filter)),

  getTourList: (filter) => dispatch(getTourList(filter)),
  getTourListMore: (filter) => dispatch(getTourListMore(filter)),

  generationEtiquette: (etiquette) => {
    dispatch(generationEtiquette(etiquette))
  },
  generationQRcode: (ids) => {
    dispatch(generationQRcode(ids))
  },
  generationTournee: (ids) => {
    dispatch(generationTournee(ids))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(SearchDownloadDocument)
