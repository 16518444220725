import { connect } from 'react-redux'
import UnservedOrders from '../../../pages/main/Programmer/Plannification/UnservedOrders'

import {
  getFilterCourseFile,
  getIsModifAdresse,
  getSelectedCourses,
  getIsUnservedReturnedReason,
  getUnservedOrdersOriginal,
} from '../../../redux/simulateTournee/selectors'
import {
  handleCheck,
  handleCheckAll,
  resetCheckedAll,
} from '../../../redux/simulateTournee/actions'

const mapStateToProps = (state) => ({
  filterCourseFile: getFilterCourseFile(state),
  isModifAdresse: getIsModifAdresse(state),
  selectedCourses: getSelectedCourses(state),
  isUnservedReturnedReason: getIsUnservedReturnedReason(state),
  unservedOrdersOriginal: getUnservedOrdersOriginal(state),
})

const mapDisptachToProps = (dispatch) => ({
  handleCheckAll: (checkedAll, isDialogCoursesReason) =>
    dispatch(handleCheckAll(checkedAll, isDialogCoursesReason)),
  handleCheck: (id) => dispatch(handleCheck(id)),
  resetCheckedAll: () => dispatch(resetCheckedAll()),
})
export default connect(mapStateToProps, mapDisptachToProps)(UnservedOrders)
