//TODO LINT
/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccordionList from '../../../../../components/AccordionList'
import ToursTable from '.'

import VolumeIcon from '../../../../../assets/icons/cube.svg'
import EuroIcon from '../../../../../assets/icons/euro.svg'
import TruckIcon from '../../../../../assets/icons/polaris/TruckIcon'
import Horloge from '../../../../../assets/icons/polaris/horlog'
import PlaceIcon from '../../../../../assets/icons/place.svg'
import TrajectoireIcon from '../../../../../assets/icons/trajectoire'
import {
  convertirMinutesEnHeuresEtMinutes,
  getCreneauHoursMinutes,
} from '../../../../../utils/utils'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '../../../../../components/switch/SwitchComponent'
import Typography from '@mui/material/Typography'
import { StyledDivSwitch, StyledHeaderPlanning } from './style'
import TruckNumberIcon from '../../../../../assets/icons/polaris/MdiTruckDeliveryOutline'
import ActionsTours from './actionTours'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Menu, MenuItem } from '@mui/material'
import FilterIcon from '../../../../../assets/icons/iconFilterTableCell'
import _ from 'lodash'

import PoidsEnlevement from '../../../../../assets/icons/polaris/PoidsEnlevement'
import PoidsLivraison from '../../../../../assets/icons/polaris/PoidsLivraison'
const ToursDetails = ({
  planning,
  focusedTours,
  isRecalculateLoading,
  tourRecChecked,
  handleCheck,
  handleCheckAll,
  onChecked,
  checked,
  setOpenDeattributeDialog,
  isOptimisationCoursesFiles,
  verrouillerTournee,
  deVerrouillerTournee,
  inversionTournee,
  expand,
  onExpend,
  inversionOrderTournee,
  reOptimizerTours,
  updateCheckTouree,
  handleDragEnd,
  //menu filtre tournee
  filterTournee,
  planningFiltered,
  setSelectedFilterTournee,
  selectedFilterTournee,
  //placement de courses
  posTournee,
  moveTableTournee,
  deattributeCourse,
}) => {
  const handleDisable = () => {
    return planningFiltered
      .map((i) => (i.markers.length === 2 ? false : true))
      .some((obj) => obj === true)
  }

  const onVerrouillerTournee = () => {
    verrouillerTournee(focusedTours)
  }

  const onDeVerrouillerTournee = () => {
    deVerrouillerTournee(focusedTours)
  }

  const [selectedAccodions, setSelectedAccodions] = useState([])

  const handleChangeAccordion = (id) => {
    const selectedIndex = selectedAccodions.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAccodions, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAccodions.slice(1))
    } else if (selectedIndex === selectedAccodions.length - 1) {
      newSelected = newSelected.concat(selectedAccodions.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAccodions.slice(0, selectedIndex),
        selectedAccodions.slice(selectedIndex + 1)
      )
    }

    setSelectedAccodions(newSelected)
  }

  const [anchorElTournee, setAnchorElTournee] = useState(null)
  const openFilterTournee = Boolean(anchorElTournee)

  const handleClickTournee = (event) => {
    setAnchorElTournee(event.currentTarget)
  }
  const handleCloseTournee = () => {
    setAnchorElTournee(null)
  }

  const verifierVisitCheck = (receptionNumber, type) => {
    return (
      tourRecChecked[receptionNumber] &&
      tourRecChecked[receptionNumber][type] !== undefined
    )
  }

  const getCheckAll = (markers) => {
    return markers.every(
      ({ receptionNumber, type }) =>
        type == 'depotStart' ||
        type == 'depotEnd' ||
        (isOptimisationCoursesFiles && tourRecChecked[receptionNumber]) ||
        (!isOptimisationCoursesFiles &&
          tourRecChecked[receptionNumber] &&
          tourRecChecked[receptionNumber].dropoff !== undefined &&
          tourRecChecked[receptionNumber].pickup !== undefined)
    )
  }
  const getNbVisitChecked = (markers) => {
    const receptionNumberTab = new Set(
      markers
        .filter((visit) => visit.type == 'dropoff' || visit.type == 'pickup')
        .map((visit) => visit.receptionNumber)
    )

    return Object.keys(tourRecChecked).reduce((count, receptionNumber) => {
      return count + (receptionNumberTab.has(receptionNumber) ? 1 : 0)
    }, 0)
  }
  return (
    <>
      <StyledHeaderPlanning
        variant="scrollable"
        sx={{
          position: 'sticky',
          top: 0,
          color: '#fff',
          zIndex: 10,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: '#1976d2',
          height: '45px',
          padding: '0 10px',
          justifyContent: 'space-between',
        }}
      >
        <Button
          id="basic-button"
          aria-controls={openFilterTournee ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterTournee ? 'true' : undefined}
          onClick={handleClickTournee}
          sx={{ color: 'white', textTransform: 'none' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedFilterTournee?.title}
            <FilterIcon
              style={{
                marginLeft: '5px',
                width: '18px',
                height: '18px',
              }}
            />
          </div>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorElTournee}
          open={openFilterTournee}
          onClose={handleCloseTournee}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {filterTournee
            .filter((el) => selectedFilterTournee.filterAff.includes(el.code))
            .map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleCloseTournee()
                    setSelectedFilterTournee(item)
                  }}
                  disabled={
                    (item.code == 'tourneesSelect' && focusedTours.length == 0) ||
                    (item.code == 'tourneesVide' &&
                      planning.every((el) => el.markers.length > 2)) ||
                    (item.code == 'tourneesNonVide' &&
                      planning.every((el) => el.markers.length == 2)) ||
                    (item.code == 'tourneesSTT' &&
                      planning.every((el) => el.driver?.name != 'STT'))
                  }
                >
                  {item.label}
                </MenuItem>
              )
            })}
        </Menu>
        <StyledDivSwitch>
          {!isOptimisationCoursesFiles && (
            <FormGroup row spacing={1} alignItems="center">
              <Typography sx={{ alignItems: 'center' }}>Magasin</Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Switch
                    value={checked}
                    defaultChecked={checked}
                    onChange={(e) => {
                      onChecked()
                      e.stopPropagation()
                    }}
                    id={'switch-MagEnt'}
                    disabled={handleDisable()}
                  />
                }
              />
              <Typography sx={{ alignItems: 'center' }}>Entrepot</Typography>
            </FormGroup>
          )}
          <>
            <ActionsTours
              isOptimisationCoursesFiles={isOptimisationCoursesFiles}
              planning={planningFiltered}
              planningOriginal={planning}
              focusedTours={focusedTours}
              setOpenDeattributeDialog={setOpenDeattributeDialog}
              onVerrouillerTournee={onVerrouillerTournee}
              onDeVerrouillerTournee={onDeVerrouillerTournee}
              inversionTournee={inversionTournee}
              inversionOrderTournee={inversionOrderTournee}
              reOptimizerTours={reOptimizerTours}
              updateCheckTouree={updateCheckTouree}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer', marginLeft: '20px' }}
              icon={expand ? faCompressAlt : faExpandAlt}
              onClick={() => onExpend(!expand)}
            />
          </>
        </StyledDivSwitch>
      </StyledHeaderPlanning>
      <AccordionList
        list={planningFiltered.map((tour, i) => {
          return {
            color: tour.color,
            heading: {
              firstName: tour.driver?.firstName,
              name: tour.driver?.name,
            },
            lock: tour.lock,
            summary: [
              {
                label: <TruckNumberIcon />,
                content: `${(tour.markers.length - 2) / 2} / ${tour.stopsNumber}`,
                unit: 'Missions / Stops',
              },
              {
                label: <img src={PlaceIcon} />,
                content: tour.distance,
                unit: 'Distance',
              },
              {
                label: <img src={EuroIcon} />,
                content: tour.montantPrestataireHT,
                unit: 'Prix',
              },
              {
                label: (
                  <PoidsEnlevement
                    color={!tour?.exceededPickupWeight ? '#373d57' : 'red'}
                  />
                ),
                content: tour.tourPickupWeight,
                unit: !tour?.exceededPickupWeight
                  ? 'Poids Livraison'
                  : `Dépassement de ${tour.exceededPickupWeight} kg`,
                color: !tour?.exceededPickupWeight ? '#373d57' : 'red',
              },
              {
                label: (
                  <PoidsLivraison
                    color={!tour?.exceededDropoffWeight ? '#373d57' : 'red'}
                  />
                ),
                content: tour.tourDropoffWeight,
                unit: !tour?.exceededDropoffWeight
                  ? 'Poids Reprises'
                  : `Dépassement de ${tour.exceededDropoffWeight} kg`,
                color: !tour?.exceededDropoffWeight ? '#373d57' : 'red',
              },

              {
                label: <img src={VolumeIcon} />,
                content: tour.totalVolume,
                unit: 'Volume',
              },
              {
                label: (
                  <Horloge color={!tour?.exceededAmplitude ? '#373d57' : 'red'} />
                ),
                content: tour.totalTime,
                unit: !tour?.exceededAmplitude
                  ? 'Temps de service'
                  : `Dépassement de ${convertirMinutesEnHeuresEtMinutes(
                      tour.exceededAmplitude
                    )}`,
                color: !tour?.exceededAmplitude ? '#373d57' : 'red',
              },
              {
                label: <TrajectoireIcon />,
                content: tour.trajetTime,
                unit: 'Temps conduite',
              },
              {
                label: <TruckIcon />,
                content: tour.driver?.vehiculeType,
                unit: 'Vehicule',
              },
            ],
            isFocused: focusedTours.includes(posTournee(tour)),
            expanded: selectedAccodions.includes(posTournee(tour)),

            index: posTournee(tour),
            path: tour.path,
            details: (
              <ToursTable
                tours={planningFiltered[i].markers
                  .filter((item) =>
                    isOptimisationCoursesFiles ? item.type != 'pickup' : item
                  )
                  .map((visit, j) => {
                    return {
                      rejectedKeys: visit.rejectedKeys,
                      checked:
                        verifierVisitCheck(visit.receptionNumber, visit.type) ||
                        false,
                      order: isOptimisationCoursesFiles ? j : visit.order,
                      location_id: visit.location_id,
                      location_name: visit.location_name,
                      ldv:
                        visit.type == 'depotStart'
                          ? 'Début de la tournée'
                          : visit.type == 'depotEnd'
                          ? 'Fin de la tournée'
                          : visit.ldv,
                      arrival: visit.arrival_time,
                      finish: visit.finish_time,
                      type: visit.type,
                      day: visit.day,
                      courseRank: visit.courseRank,
                      too_late: visit.too_late,
                      weight: visit.weight,
                      volume: visit.volume,
                      montantPrestataireHT: visit.montantPrestaHT,
                      courseType: visit.courseType,
                      pickup:
                        visit.type == 'pickup'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff1:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff2:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.secondWindowStart,
                              visit.secondWindowEnd,
                              '~'
                            )
                          : '-',
                      courseCode: visit.location_name,
                      salesChanel: visit.salesChanel,
                      indexVisit: j,
                      receptionNumber: visit.location_id,
                      idleTime: visit.idleTime,
                      client: visit.client,
                      prestaTime: visit.prestaTime,
                      codePostale: visit.codePostale,
                      keys: visit.keys,
                      lateBy: visit.lateBy,
                      slotOverRun: visit.slotOverRun,
                      orderGiver: visit.orderGiver,
                      city: visit.city,
                      distance: visit.distance,
                    }
                  })}
                index={posTournee(tour)}
                isOpen={selectedAccodions.includes(posTournee(tour))}
                isRecalculateLoading={isRecalculateLoading}
                handleCheck={handleCheck}
                handleCheckAll={handleCheckAll}
                indexPlanning={posTournee(tour)}
                checkedAll={getCheckAll(tour.markers)}
                nbVisitChecked={getNbVisitChecked(tour.markers)}
                isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                lock={tour.lock}
                planning={planningFiltered}
                tour={tour}
                disabledActionMov={
                  planningFiltered[i].markers.length == 0 ||
                  planningFiltered[i].markers.every(
                    (marker) =>
                      !Object.keys(tourRecChecked).includes(marker.receptionNumber)
                  )
                }
                moveTableTournee={moveTableTournee}
                deattributeCourse={deattributeCourse}
                handleDragEnd={handleDragEnd}
              />
            ),
            handleChange: handleChangeAccordion,
          }
        })}
      />
    </>
  )
}
ToursDetails.propTypes = {
  focusedTours: PropTypes.array,
  isRecalculateLoading: PropTypes.bool,
  planning: PropTypes.any,
  handleCheck: PropTypes.func,
  handleCheckAll: PropTypes.func,
  onChecked: PropTypes.func,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  setSizeTable: PropTypes.func,
  setOpenDeattributeDialog: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  verrouillerTournee: PropTypes.func,
  deVerrouillerTournee: PropTypes.func,
  inversionTournee: PropTypes.func,
  inversionOrderTournee: PropTypes.func,
  filterTournee: PropTypes.array,
  planningFiltered: PropTypes.array,
  setSelectedFilterTournee: PropTypes.func,
  selectedFilterTournee: PropTypes.object,
  reOptimizerTours: PropTypes.func,
  posTournee: PropTypes.func,
  moveTableTournee: PropTypes.func,
  deattributeCourse: PropTypes.func,
  updateCheckTouree: PropTypes.func,
  handleDragEnd: PropTypes.func,
  tourRecChecked: PropTypes.object,
}

export default ToursDetails
