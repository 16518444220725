import React from 'react'
import PropTypes from 'prop-types'

import TextComponent from './texComponent'
import { StyledCell } from './style'

const ColumnTextComponent = ({ content, key, id, disabledColumn }) => {
  return (
    <StyledCell align="center" id={id} disabledColumn={disabledColumn}>
      <TextComponent content={content} key={key} />
    </StyledCell>
  )
}

ColumnTextComponent.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  disabledColumn: PropTypes.bool,
}
export default ColumnTextComponent
