import React from 'react'
import PropTypes from 'prop-types'

import {
  checkActionsUservedOrders,
  columnsListUservedOrdersReason,
  columnsOptimisationCoursesFileUservedReason,
  settingsTable,
} from './data'
import TableVirtulisation from './TableVirtulisation'
import Box from '@mui/material/Box'
import Dialog from '../../../../../components/ModalDialog'
import LowPriorityIcon from '@mui/icons-material/LowPriority'

const ReasonCourses = ({
  setOpenDialogModifCourses,
  openDialogModifCourses,
  openDialogReasonCourses,
  isOptimisationCoursesFiles,
  unservedCoursesReason,
  modifierCourse,
  moveTableUnserved,
  planningFiltered,
  selectedCourses,
  handleCheckAllOrder,
  verifDisabledCheckAll,
  handleCheckOrder,
  resetCheckedAll,

  isSimError,
  isSimLoading,
  closeDialogUnservedReason,
}) => {
  const columnsHeaderListReason = [
    ...checkActionsUservedOrders,
    ...(isOptimisationCoursesFiles
      ? columnsOptimisationCoursesFileUservedReason
      : columnsListUservedOrdersReason),
    ...settingsTable,
  ]
  const columnsContentListReason = [
    ...(isOptimisationCoursesFiles
      ? columnsOptimisationCoursesFileUservedReason
      : columnsListUservedOrdersReason),
  ]

  return (
    <Dialog
      fullWidth={true}
      maxWidthDialog={'xl'}
      open={openDialogReasonCourses}
      title={'Positions non planifiées'}
      style={
        isSimLoading || isSimError || openDialogModifCourses
          ? { display: 'none' }
          : {}
      }
      iconTitle={
        <LowPriorityIcon
          sx={{
            width: '20px',
            height: '20px',
            marginRight: '9px',
          }}
        />
      }
      content={
        <Box
          style={{
            paddingRight: '16px',
            height: 400,
            width: '100%',
          }}
        >
          <TableVirtulisation
            unservedOrders={unservedCoursesReason}
            resetCheckedAll={resetCheckedAll}
            isOptimisationCoursesFiles={isOptimisationCoursesFiles}
            filtersValues={{}}
            setFilters={() => {}}
            moveTableUnserved={moveTableUnserved}
            planningFiltered={planningFiltered}
            filterCourseFile={{}}
            selectedCourses={selectedCourses}
            columnsHeaderList={columnsHeaderListReason}
            columnsContentList={columnsContentListReason}
            modifierCourse={modifierCourse}
            handleCheckAllOrder={handleCheckAllOrder}
            verifDisabledCheckAll={verifDisabledCheckAll}
            handleCheckOrder={handleCheckOrder}
            isDialogReasonCoursesOpened={openDialogReasonCourses}
            isOriginalTabUnserved={false}
          />
        </Box>
      }
      handleClose={() => {
        setOpenDialogModifCourses(false)
        closeDialogUnservedReason()
      }}
      isModalActionFermer={true}
    />
  )
}
ReasonCourses.propTypes = {
  unservedOrders: PropTypes.array,
  setOpenDialogModifCourses: PropTypes.func,
  openDialogModifCourses: PropTypes.bool,
  openDialogReasonCourses: PropTypes.bool,
  isOptimisationCoursesFiles: PropTypes.bool,
  unservedCoursesReason: PropTypes.array,
  modifierCourse: PropTypes.func,
  moveTableUnserved: PropTypes.func,
  planningFiltered: PropTypes.array,
  selectedCourses: PropTypes.array,
  handleCheckAllOrder: PropTypes.func,
  verifDisabledCheckAll: PropTypes.func,
  resetCheckedAll: PropTypes.func,
  handleCheckOrder: PropTypes.func,
  isSimError: PropTypes.bool,
  isSimLoading: PropTypes.bool,
  closeDialogUnservedReason: PropTypes.func,
}

export default ReasonCourses
