import { connect } from 'react-redux'

import { getDirectionRoute } from '../../../redux/googleMapDirections/selectors'
import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'
import SimulateMap from '../../../pages/main/Programmer/Plannification/SimulateMap'
import {
  getFocusedTours,
  getSelectedCourses,
  getcheckedRecMap,
} from '../../../redux/simulateTournee/selectors'

import {
  addSelectedCourses,
  removeAllSelectedCourses,
  removeSelectedCourses,
} from '../../../redux/simulateTournee/actions'
const mapStateToProps = (state) => ({
  directionRoute: getDirectionRoute(state),
  focusedTours: getFocusedTours(state),
  selectedCourses: getSelectedCourses(state),
  tourRecChecked: getcheckedRecMap(state),
})
const mapDisptachToProps = (dispatch) => ({
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),
  removeSelectedCourses: (data) => dispatch(removeSelectedCourses(data)),
  addSelectedCourses: (data) => dispatch(addSelectedCourses(data)),
  removeAllSelectedCourses: () => dispatch(removeAllSelectedCourses()),
})

export default connect(mapStateToProps, mapDisptachToProps)(SimulateMap)
