export const LANGUAGE = 'fr'
export const ROLE = {
  PARTICULIER: 'Particulier',
  PROFESSIONEL: 'Professionnel',
}
export const PaymentInitial = [
  { code: 'initial', label: 'Sélectionner un moyen de paiement' },
]
export const LEVEL_NUMBERS = [-3, -2, -1].concat(Array.from(Array(21).keys()))
export const GOOGLE_DEFAULT_COUNTRY = { code: 'fr', name: 'France' }
export const GOOGLE_ADDRESS_TYPE = 'google'
export const SALES_CHANNEL_ADDRESS_TYPE = 'salesChannel'
export const HITORY_ADDRESS_TYPE = 'history'
export const COMPANY_ADDRESS_TYPE = 'company'
export const GOOGLE_COUNTRIES = [
  GOOGLE_DEFAULT_COUNTRY,
  { code: 'ch', name: 'Suisse (Switzerland)' },
  { code: 'be', name: 'Belgique (Belgium)' },
  { code: 'de', name: 'Allemagne (Germany)' },
  { code: 'es', name: 'Espagne (Spain)' },
  { code: 'it', name: 'Italie (Italy)' },
  { code: 'lu', name: 'Luxembourg' },
  { code: 'nl', name: 'Pays-Bas (Netherlands)' },
  { code: 'pt', name: 'Portugal' },
]
export const DAYS_OF_WEEK = Object.freeze([
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
])
export const DATE_FORMAT = 'YYYY-MM-DD'
export const PICKER_DATE_FORMAT = 'dd/MM/yyyy'
export const PICKER_TIME_FORMAT = 'HH:mm'
export const PICKER_TIME_VIEWS = ['hours']
export const REDUCTION_TYPE = {
  FIXE: 'fixe',
  POURCENTAGE: 'pourcentage',
}

export const ORDER_TYPE = {
  DELIVERY: 'course',
  ASSEMBLY: 'montage',
  DELIVERY_ASSEMBLY: 'course_montage',
}
export const DEFAULT_SIZE = 'M'
export const ASSEMBLY_TIME_LIST = [
  {
    name: '09:00h',
    start: '09:00',
    value: 9,
    hr: '09',
    fullHr: '09:00:00',
  },
  {
    name: '10:00h',
    start: '10:00',
    value: 10,
    hr: '10',
    fullHr: '10:00:00',
  },
  {
    name: '11:00h',
    start: '11:00',
    value: 11,
    hr: '11',
    fullHr: '11:00:00',
  },
  {
    name: '12:00h',
    start: '12:00',
    value: 12,
    hr: '12',
    fullHr: '12:00:00',
  },
  {
    name: '13:00h',
    start: '13:00',
    value: 13,
    hr: '13',
    fullHr: '13:00:00',
  },
  {
    name: '14:00h',
    start: '14:00',
    value: 14,
    hr: '14',
    fullHr: '14:00:00',
  },
  {
    name: '15:00h',
    start: '15:00',
    value: 15,
    hr: '15',
    fullHr: '15:00:00',
  },
  {
    name: '16:00h',
    start: '16:00',
    value: 16,
    hr: '16',
    fullHr: '16:00:00',
  },
  {
    name: '17:00h',
    start: '17:00',
    value: 17,
    hr: '17',
    fullHr: '17:00:00',
  },
  {
    name: '18:00h',
    start: '18:00',
    value: 18,
    hr: '18',
    fullHr: '18:00:00',
  },
]

export const SOURCE = {
  CORNER: 'corner',
  MELTING_POINT: 'melting_point',
}
