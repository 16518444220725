import { connect } from 'react-redux'
import ToursDetails from '../../../pages/main/Programmer/Plannification/Tours/ToursDetails'
import {
  handleCheckAllVisit,
  handleCheckVisit,
  verrouillerTournee,
  deVerrouillerTournee,
  updateCheckTouree,
} from '../../../redux/simulateTournee/actions'
import {
  getSimulationTours,
  getFocusedTours,
  getSimulationsIsLoading,
  getcheckedRec,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  planning: getSimulationTours(state),
  focusedTours: getFocusedTours(state),
  isRecalculateLoading: getSimulationsIsLoading(state),
  tourRecChecked: getcheckedRec(state),
})

const mapDisptachToProps = (dispatch) => ({
  updateCheckTouree: (deselection, id) => {
    dispatch(updateCheckTouree(deselection, id))
  },
  handleCheckAll: (indexPlanning, checkedAll) =>
    dispatch(handleCheckAllVisit(indexPlanning, checkedAll)),
  handleCheck: (receptionNumber, type) =>
    dispatch(handleCheckVisit(receptionNumber, type)),
  verrouillerTournee: (idDriver) => {
    dispatch(verrouillerTournee(idDriver))
  },
  deVerrouillerTournee: (idDriver) => {
    dispatch(deVerrouillerTournee(idDriver))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(ToursDetails)
