import {
  GET_ETIQUETTE_LIST,
  GENERATION_ETIQUETTE,
  GENERATION_TRANSFERT,
  GENERATION_PREPARATION,
  GENERATION_BONLIVRAISON,
  GENERATION_BONLIVRAISON_COURSE,
  GET_LIVRAISON_LIST,
  GET_FREIGHTS_LIST,
  CLOTURE_RECEPTION_FREIGHTS,
  CLOTURE_RECEPTION_FREIGHTS_SUCCESS,
  CLOTURE_RECEPTION_FREIGHTS_FAILURE,
  GET_HISTORIQUE_FREIGHTS,
  GENERATION_QRCODE,
  GET_ETIQUETTE_LIST_MORE,
  GENERATION_TOURNEE,
  DOWNLOAD_KILOMETRAGE_TOURS,
  GET_CANAL_VENTE_DASH,
  GET_CANAL_VENTE_DASH_MORE,
} from './constants'

export const fetchEtiquetteList = (filter) => ({
  type: GET_ETIQUETTE_LIST,
  payload: filter,
})

export const fetchEtiquetteListMore = (filter) => ({
  type: GET_ETIQUETTE_LIST_MORE,
  payload: filter,
})

export const generationEtiquette = (etiquette) => ({
  type: GENERATION_ETIQUETTE,
  payload: { barcode: etiquette.barCode, ldv: etiquette.LettreDeVoiture },
})

export const downloadTransfert = () => ({
  type: GENERATION_TRANSFERT,
})

export const downloadPreparation = () => ({
  type: GENERATION_PREPARATION,
})

export const downloadBonLivraison = () => ({
  type: GENERATION_BONLIVRAISON,
})

export const generationBLCourse = (ldv) => ({
  type: GENERATION_BONLIVRAISON_COURSE,
  payload: { ldv },
})

export const fetchLivraison = () => ({
  type: GET_LIVRAISON_LIST,
})

export const fetchFreights = () => ({
  type: GET_FREIGHTS_LIST,
})

export const clotureReception = (ids) => ({
  type: CLOTURE_RECEPTION_FREIGHTS,
  payload: { ids },
})

export const clotureReceptionSuccess = () => ({
  type: CLOTURE_RECEPTION_FREIGHTS_SUCCESS,
})

export const clotureReceptionFailure = () => ({
  type: CLOTURE_RECEPTION_FREIGHTS_FAILURE,
})

export const fetchHistoriqueFreights = (id) => ({
  type: GET_HISTORIQUE_FREIGHTS,
  payload: { id },
})

export const generationQRcode = (ids) => ({
  type: GENERATION_QRCODE,
  payload: { ids: ids },
})

export const generationTournee = (ids) => ({
  type: GENERATION_TOURNEE,
  payload: { ids: ids },
})

export const generationKilometrageTournee = (payload) => ({
  type: DOWNLOAD_KILOMETRAGE_TOURS,
  payload,
})

export const fetchCanalVenteDash = (payload) => ({
  type: GET_CANAL_VENTE_DASH,
  payload,
})

export const fetchCanalVenteDashMore = (payload) => ({
  type: GET_CANAL_VENTE_DASH_MORE,
  payload,
})
