import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@mui/icons-material/GetApp'
import AutoComplete from '../../../AutoComplete'
import SearchIcon from '@mui/icons-material/Search'

import Dialog from '../../../../components/ModalDialog'

export default function SearchDownloadMultipleDocument({
  open,
  title,
  iconTitle,
  placeholderInput,
  onSearch,
  isLoading,
  options,
  countOptions,
  onSearchMore,
  sxInput,
  downloadDocument,
  handleClose,
}) {
  const [select, setSelect] = useState(false)
  const [valueSelect, setValueSelect] = useState([])
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(25)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0 && !select) {
        const filter = {
          limit: 25,
          offset: 0,
          query: search.trim().toUpperCase(),
        }
        onSearch(filter)
      } else if (search == '') {
        const filter = {
          limit: 25,
          offset: 0,
        }
        onSearch(filter)
      }
      setOffset(25)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    setSelect(false)
    if (value) {
      let val = value
      setSearch(val)
    } else {
      setSearch('')
    }
  }

  const onSelect = (value) => {
    if (value) {
      setValueSelect(value)
      setSelect(value.length > 0)
    }
  }

  const loadMore = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      countOptions >= offset
    ) {
      setOffset(offset + 25)
      const filter = {
        limit: 25,
        offset: offset,
        query: search.length > 0 ? search.trim().toUpperCase() : undefined,
      }
      onSearchMore(filter)
    }
  }

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={title}
      iconTitle={iconTitle}
      fullWidth={true}
      content={
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '0px 16px 16px 0px',
            borderRadius: '5px',
          }}
        >
          <div style={{ width: select ? '91%' : '100%' }}>
            <AutoComplete
              freeSolo
              value={valueSelect}
              name={'search-multiple'}
              onChange={(event, value) => {
                onSelect(value)
              }}
              onInputChange={(event, value) => onChangeSearch(value)}
              id="Search-Multiple"
              options={options}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              variant="outlined"
              placeholder={placeholderInput}
              sxInputStyle={sxInput}
              multiple={true}
              fullWidth={true}
              ListboxProps={{
                onScroll: loadMore,
              }}
              loading={isLoading}
              inputValue={search}
              startIcon={
                <SearchIcon
                  sx={{
                    paddingRight: '4px',
                    width: '26px !important',
                    height: '26px !important',
                    color: '#C2C2C2 !important',
                  }}
                />
              }
            />
          </div>
          {select && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <GetAppIcon
                onClick={() =>
                  downloadDocument(valueSelect.map((value) => value.id).join(','))
                }
                style={{ cursor: 'pointer', fontSize: '30px' }}
              />
            </div>
          )}
        </div>
      }
      handleClose={handleClose}
      isClosedIcon={true}
    />
  )
}

SearchDownloadMultipleDocument.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  iconTitle: PropTypes.any,
  placeholderInput: PropTypes.string,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  countOptions: PropTypes.number,
  onSearchMore: PropTypes.func,
  sxInput: PropTypes.object,
  downloadDocument: PropTypes.func,
  handleClose: PropTypes.func,
}
