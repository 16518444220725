export default {
  isLoading: false,
  tours: [],
  tourRecChecked: {},
  errorMessage: '',
  isError: false,
  unservedOrders: [],
  focusedTours: [],
  fleet: [],
  deliveryDate: new Date(),
  checkedAllOrder: false,

  isOnePickUp: false,
  isErrorAffectPlanning: false,
  isViewPlannification: false,
  isLoadingUpdateCoursePolaris: false,
  isUpdateCoursePolarisAction: false,
  tourAction: false,
  tourList: [],
  countTours: 0,
  isOptimisationCoursesFiles: false,
  isModifAdresse: false,

  warehouses: [],
  warehousesCount: 0,
  isLoadingWarehouses: false,
  filter: {
    limit: 25,
    offset: 0,
  },
  filterCourseFile: {},
  filterCourses: {},
  unservedOrdersOriginal: [],
  selectedCourses: [],

  fileNameCoursesOptimisation: '',
  isDownload: false,

  isUnservedReturnedReason: false,
}
