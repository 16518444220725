import { connect } from 'react-redux'
import ReasonCourses from '../../../pages/main/Programmer/Plannification/UnservedOrders/reasonCoursesDialog'

import {
  getSimulationIsError,
  getSimulationsIsLoading,
} from '../../../redux/simulateTournee/selectors'
import { closeDialogUnservedReason } from '../../../redux/simulateTournee/actions'

const mapStateToProps = (state) => ({
  isSimError: getSimulationIsError(state),
  isSimLoading: getSimulationsIsLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  closeDialogUnservedReason: () => dispatch(closeDialogUnservedReason()),
})
export default connect(mapStateToProps, mapDisptachToProps)(ReasonCourses)
