import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'

import { ACCURATE_ADDRESS } from '../../../../../utils/values'

import {
  checkActionsUservedOrders,
  columnsListUservedOrders,
  columnsOptimisationCoursesFileUservedOrders,
  settingsTable,
} from './data'
import TableVirtulisation from './TableVirtulisation'
import ModificationCoursesDialog from '../../../../../containers/main/Simuler/modificationCoursesDialog'

import ReasonCoursesDialog from '../../../../../containers/main/Simuler/reasonCoursesDialog'

const UnservedOrders = ({
  unservedOrders,
  handleCheckAll,
  handleCheck,
  resetCheckedAll,
  isOptimisationCoursesFiles,
  filtersValues,
  setFilters,
  moveTableUnserved,
  planningFiltered,
  filterCourseFile,
  isModifAdresse,
  selectedCourses,
  isUnservedReturnedReason,
  unservedOrdersOriginal,
}) => {
  const [openDialogModifCourses, setOpenDialogModifCourses] = useState(false)
  const [unservedCoursesModif, setUnservedCoursesModif] = useState([])
  const [openDialogReasonCourses, setOpenDialogReasonCourses] = useState(false)
  const [unservedCoursesReason, setUnservedCoursesReason] = useState([])

  useEffect(() => {
    if (isModifAdresse) {
      setOpenDialogModifCourses(true)

      setUnservedCoursesModif(
        unservedOrders.filter(
          (course) => !ACCURATE_ADDRESS.includes(course.locationType)
        )
      )
    } else {
      setOpenDialogModifCourses(false)
    }
  }, [isModifAdresse, unservedOrders])

  useEffect(() => {
    if (isUnservedReturnedReason) {
      setOpenDialogReasonCourses(true)

      setUnservedCoursesReason(
        unservedOrdersOriginal.filter((course) => course?.reason)
      )
    } else {
      setOpenDialogReasonCourses(false)
    }
  }, [isUnservedReturnedReason, unservedOrdersOriginal])

  const handleCheckAllOrder = (event, isDialogCourses) => {
    handleCheckAll(event.target.checked, isDialogCourses)
  }

  const verifDisabledCheckAll = () => {
    return (
      unservedOrders.filter(
        (e) =>
          isOptimisationCoursesFiles && !ACCURATE_ADDRESS.includes(e.locationType)
      ).length == unservedOrders.length
    )
  }

  const handleCheckOrder = (id) => {
    handleCheck(id)
  }

  const modifierCourse = () => {
    setOpenDialogModifCourses(true)
    setUnservedCoursesModif(
      unservedOrders.filter((course) => selectedCourses.includes(course.id))
    )
  }
  const columnsHeaderList = [
    ...checkActionsUservedOrders,
    ...(isOptimisationCoursesFiles
      ? columnsOptimisationCoursesFileUservedOrders
      : columnsListUservedOrders),
    ...settingsTable,
  ]

  const columnsContentList = [
    ...(isOptimisationCoursesFiles
      ? columnsOptimisationCoursesFileUservedOrders
      : columnsListUservedOrders),
  ]

  return (
    <Paper
      className="order-table-root"
      style={{
        margin: 0,
        width: '100%',
        backgroundColor: 'transparent',
        overflow: 'auto',
        height: 'calc(100% - 45px )',
      }}
    >
      <TableVirtulisation
        unservedOrders={unservedOrders}
        resetCheckedAll={resetCheckedAll}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
        filtersValues={filtersValues}
        setFilters={setFilters}
        moveTableUnserved={moveTableUnserved}
        planningFiltered={planningFiltered}
        filterCourseFile={filterCourseFile}
        selectedCourses={selectedCourses}
        //
        columnsHeaderList={columnsHeaderList}
        columnsContentList={columnsContentList}
        modifierCourse={modifierCourse}
        handleCheckAllOrder={(event) => handleCheckAllOrder(event, false)}
        verifDisabledCheckAll={verifDisabledCheckAll}
        handleCheckOrder={handleCheckOrder}
        isDialogReasonCoursesOpened={openDialogReasonCourses}
        isOriginalTabUnserved={true}
      />

      {openDialogModifCourses && (
        <ModificationCoursesDialog
          open={openDialogModifCourses}
          handleClose={() => setOpenDialogModifCourses(false)}
          unservedOrders={unservedCoursesModif}
        />
      )}
      {openDialogReasonCourses && (
        <ReasonCoursesDialog
          setOpenDialogModifCourses={setOpenDialogModifCourses}
          openDialogModifCourses={openDialogModifCourses}
          openDialogReasonCourses={openDialogReasonCourses}
          isOptimisationCoursesFiles={isOptimisationCoursesFiles}
          unservedCoursesReason={unservedCoursesReason}
          modifierCourse={modifierCourse}
          moveTableUnserved={moveTableUnserved}
          planningFiltered={planningFiltered}
          selectedCourses={selectedCourses}
          handleCheckAllOrder={(event) => handleCheckAllOrder(event, true)}
          verifDisabledCheckAll={verifDisabledCheckAll}
          handleCheckOrder={handleCheckOrder}
          resetCheckedAll={resetCheckedAll}
        />
      )}
    </Paper>
  )
}
UnservedOrders.propTypes = {
  unservedOrders: PropTypes.array,
  handleCheckAll: PropTypes.func,
  handleCheck: PropTypes.func,
  resetCheckedAll: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  filtersValues: PropTypes.object,
  setFilters: PropTypes.object,
  moveTableUnserved: PropTypes.func,
  planningFiltered: PropTypes.array,
  filterCourseFile: PropTypes.object,
  isModifAdresse: PropTypes.bool,
  selectedCourses: PropTypes.array,
  isUnservedReturnedReason: PropTypes.bool,
  unservedOrdersOriginal: PropTypes.array,
}

export default UnservedOrders
